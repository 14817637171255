import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { IconComponent } from './icon';
import { SVG_ICONS, SvgIconDefinition } from './icon.types';
import { IconRegistry } from './icon.registry';
import { DomSanitizer } from '@angular/platform-browser';
import { coerceArray } from '@angular/cdk/coercion';

@NgModule({
  imports: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {
  private sanitizer = inject(DomSanitizer);
  private registry = inject(IconRegistry);

  static register(icons: SvgIconDefinition | SvgIconDefinition[]): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [{ provide: SVG_ICONS, useValue: icons }],
    };
  }

  constructor() {
    const icons = inject(SVG_ICONS, { optional: true });

    if (icons) {
      for (const { name, content } of coerceArray(icons)) {
        this.registry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustHtml(content));
      }
    }
  }
}
