import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { IconRegistry } from './icon.registry';

/**
 * Returns an exception to be thrown in the case when attempting to
 * load an icon with a name that cannot be found.
 * @docs-private
 */
export function getIconNameNotFoundError(iconName: string): Error {
  return Error(`Unable to find icon with the name "${iconName}"`);
}

@Component({
  standalone: true,
  selector: 'wma-icon',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'img',
    'aria-hidden': 'true',
    '[class.inline-block]': 'true',
  },
})
export class IconComponent {
  private host = inject(ElementRef);
  private registry = inject(IconRegistry);

  /** The name of the SVG icon to draw. */
  name = input.required<string>();

  constructor() {
    effect(() => {
      const value = this.name();
      if (value) {
        this.setSvgElement(value);
      } else {
        this.clearSvgElement();
      }
    });
  }

  private get element(): HTMLElement {
    return this.host.nativeElement;
  }

  private setSvgElement(value: string) {
    this.clearSvgElement();

    const svg = this.registry.getSvgIcon(value);

    if (svg) {
      this.element.appendChild(svg);
    } else {
      throw getIconNameNotFoundError(value);
    }
  }

  private clearSvgElement() {
    const firstChild = this.element.firstChild;
    if (firstChild) {
      this.element.removeChild(firstChild);
    }
  }
}
