import { NgModule } from '@angular/core';
import { AnchorComponent, ButtonComponent } from './button';
import { SpinnerComponent } from '../spinner';

export const BUTTON_DIRECTIVES = [ButtonComponent, AnchorComponent];

@NgModule({
  declarations: [BUTTON_DIRECTIVES],
  exports: [BUTTON_DIRECTIVES],
  imports: [SpinnerComponent],
})
export class ButtonModule {}
