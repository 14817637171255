import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type ButtonColor = 'primary' | 'secondary' | 'white' | 'danger' | 'neutral';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

const primaryButtonClasses = [
  'text-white',
  'bg-primary-600',
  'hover:bg-primary-500',
  'focus:ring-primary-500',
  'active:bg-primary-700',
];
const dangerButtonClasses = [
  'text-white',
  'bg-danger-600',
  'hover:bg-danger-500',
  'focus:ring-danger-500',
  `active:bg-danger-700`,
];
const secondaryButtonClasses = [
  'text-primary-700',
  'bg-primary-100',
  'hover:bg-primary-200',
  'focus:ring-primary-500',
  'active:bg-primary-200',
];
const whiteButtonClasses = [
  `text-primary-600`,
  `ring-1`,
  `ring-gray-300`,
  `bg-white`,
  `hover:bg-primary-50`,
  'focus:ring-primary-500',
  `active:bg-primary-50`,
];

const neutralButtonClasses = [
  `text-gray-900`,
  `ring-1`,
  `ring-gray-300`,
  `bg-white`,
  `hover:bg-gray-50`,
  'focus:ring-gray-500',
  `active:bg-gray-50`,
];

const defaultColor: ButtonColor = 'primary';
const defaultSize: ButtonSize = 'md';

const colors: Record<ButtonColor, string[]> = {
  primary: primaryButtonClasses,
  secondary: secondaryButtonClasses,
  white: whiteButtonClasses,
  danger: dangerButtonClasses,
  neutral: neutralButtonClasses,
};
const sizes: Record<ButtonSize, string[]> = {
  xs: ['px-2', 'py-1', 'text-xs', 'rounded'],
  sm: ['px-2', 'py-1', 'text-sm', 'rounded-md'],
  md: ['px-2.5', 'py-1.5', 'text-sm', 'rounded-md'],
  lg: ['px-3', 'py-2', 'text-sm', 'rounded-md'],
};

@Component({
  // According to the [Angular styleguide](https://angular.io/guide/styleguide#style-05-03),
  // components should only use an element selector except when we want to augment a built-in element.
  // In our case, we want to extend the native button element
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[wma-button]',
  templateUrl: './button.html',
  host: {
    '[class]': 'classes()',
    '[attr.disabled]': 'disabled() || loading() || null',
    '[class.opacity-50]': 'disabled() || loading()',
    '[class.relative]': 'loading()',
    '[class.cursor-not-allowed]': 'disabled() && !loading()',
    '[class.cursor-wait]': 'loading()',
  },
  exportAs: 'wmaButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  color = input<ButtonColor>();
  size = input<ButtonSize>();

  disabled = input<boolean, boolean | ''>(false, {
    transform: coerceBooleanProperty,
  });

  loading = input<boolean, boolean | ''>(false, {
    transform: coerceBooleanProperty,
  });

  classes = computed(() => {
    return [
      ...['inline-flex', 'items-center', 'justify-center'],
      ...['font-semibold'],
      ...['focus:outline-none', 'focus:ring-2', 'focus:ring-offset-2'],
      ...colors[this.color() || defaultColor],
      ...sizes[this.size() || defaultSize],
    ];
  });
}

@Component({
  // According to the [Angular styleguide](https://angular.io/guide/styleguide#style-05-03),
  // components should only use an element selector except when we want to augment a built-in element.
  // In our case, we want to extend the native link element
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[wma-button]',
  templateUrl: './button.html',
  host: {
    '[class]': 'classes()',
    '[attr.disabled]': 'disabled() || null',
    '[attr.tabindex]': 'disabled() ? -1 : (tabIndex() || 0)',
    '[attr.aria-disabled]': 'disabled().toString()',
    '(click)': '_haltDisabledEvents($event)',
    '[class.opacity-50]': 'disabled()',
    '[class.cursor-not-allowed]': 'disabled()',
  },
  exportAs: 'wmaAnchor',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorComponent extends ButtonComponent {
  /** Tabindex of the button. */
  tabIndex = input<number>();

  _haltDisabledEvents(event: Event): void {
    if (this.disabled()) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
