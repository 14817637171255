import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'xl';
const defaultSize: AvatarSize = 'md';

const imageSizes: Record<AvatarSize, string[]> = {
  xs: ['size-8'],
  sm: ['size-10'],
  md: ['size-14'],
  xl: ['size-20'],
};

const initialsSizes: Record<AvatarSize, string[]> = {
  xs: ['text-base'],
  sm: ['text-base'],
  md: ['text-2xl'],
  xl: ['text-3xl'],
};

@Component({
  standalone: true,
  selector: 'wma-avatar',
  exportAs: 'wmaAvatar',
  templateUrl: './avatar.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'sizeClasses()',
    '[class.inline-block]': 'true',
  },
})
export class AvatarComponent {
  src = input<string | null>();
  alt = input<string>();
  initials = input<string>('');
  size = input<AvatarSize>();

  sizeClasses = computed(() => {
    const sizesClasses = imageSizes[this.size() || defaultSize];
    return sizesClasses.join(' ');
  });
  initialsClasses = computed(() => {
    const sizes = initialsSizes[this.size() || defaultSize];
    return [...sizes];
  });
}
