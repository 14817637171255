<label for="{{ id }}" class="sr-only">{{ label() }}</label>
<div class="relative flex grow items-stretch focus-within:z-10">
  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
    <wma-icon class="size-5 text-gray-400" name="search"></wma-icon>
  </div>
  <input
    id="{{ id }}"
    type="search"
    class="wma-input pl-10"
    [attr.placeholder]="placeholder()"
    [formControl]="search"
    (blur)="onTouched()"
  />
</div>
