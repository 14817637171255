<nav class="flex items-center justify-between gap-x-4 px-4 py-3 sm:px-6">
  <p class="hidden shrink-0 sm:block">
    {{ numberOfElements() === 0 ? 0 : number() * size() + 1 }} -
    {{ number() * size() + numberOfElements() }} sur {{ totalElements() }}
  </p>

  <div class="flex items-center gap-2">
    <span>Page </span>
    <select
      #select
      [value]="number()"
      id="page"
      class="wma-select"
      [formControl]="control"
      (change)="onSelectChange(select.value)"
    >
      @for (page of pages(); track page) {
        <option [value]="page">
          {{ page + 1 }}
        </option>
      }
    </select>
    <span class="hidden shrink-0 sm:inline">sur {{ totalPages() }}</span>
  </div>

  <div class="flex items-center gap-x-2">
    <button wma-button size="md" [disabled]="first()" color="white" (click)="goToPrevious()">
      Précédent
    </button>
    <button wma-button [disabled]="last()" color="white" (click)="goToNext()">Suivant</button>
  </div>
</nav>
