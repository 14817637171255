import { NgModule } from '@angular/core';
import {
  CardComponent,
  CardContentDirective,
  CardFooterDirective,
  CardHeaderDirective,
} from './card.component';

export const CARD_DIRECTIVES = [
  CardComponent,
  CardContentDirective,
  CardHeaderDirective,
  CardFooterDirective,
];

@NgModule({
  imports: [CARD_DIRECTIVES],
  exports: [CARD_DIRECTIVES],
})
export class CardModule {}
