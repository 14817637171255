import { Component, Directive } from '@angular/core';

@Directive({
  standalone: true,
  selector: `wma-card-header, [wma-card-header], [wmaCardHeader]`,
  host: {
    class: 'block bg-gray-50 px-4 py-5 sm:px-6',
  },
})
export class CardHeaderDirective {}

@Directive({
  standalone: true,
  selector: `wma-card-content, [wma-card-content], [wmaCardContent]`,
  host: {
    class: 'px-4 py-5 sm:p-6 block',
  },
})
export class CardContentDirective {}

@Directive({
  standalone: true,
  selector: `wma-card-footer, [wma-card-footer], [wmaCardFooter]`,
  host: {
    class: 'px-4 py-5 sm:px-6 block',
  },
})
export class CardFooterDirective {}

@Component({
  standalone: true,
  selector: 'wma-card',
  templateUrl: './card.component.html',
  host: {
    class:
      'block bg-white overflow-hidden border border-gray-300 rounded-lg divide-y divide-gray-200',
  },
})
export class CardComponent {}
