<style>
  :host {
    --wma-spinner-track-color: transparent;
  }
  :host span {
    border-bottom-color: var(--wma-spinner-track-color);
    border-left-color: var(--wma-spinner-track-color);
  }
</style>

<span
  [class]="css()"
  class="inline-block h-full w-full animate-spin rounded-full"
  aria-live="polite"
  aria-busy="true"
></span>
