import { inject, Injectable, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Returns an exception to be thrown when an HTML string couldn't be sanitized.
 * @param content HTML that was attempted to be sanitized.
 */
export function getContentFailedToBeSanitizedError(content: SafeHtml): Error {
  return Error(
    `The content provided was not trusted as safe HTML by Angular's DomSanitizer.` +
      `Attempted content was "${content}".`,
  );
}

class SvgIconConfig {
  svgElement: SVGSVGElement | null = null;
  constructor(public content: SafeHtml) {}
}

@Injectable({ providedIn: 'root' })
export class IconRegistry {
  private document = inject(DOCUMENT);
  private sanitizer = inject(DomSanitizer);

  private svgMap = new Map<string, SvgIconConfig>();

  addSvgIcon(name: string, content: SafeHtml): this {
    const sanitizedHtml = this.sanitizer.sanitize(SecurityContext.HTML, content);

    if (!sanitizedHtml) {
      throw getContentFailedToBeSanitizedError(content);
    }

    this.svgMap.set(name, new SvgIconConfig(sanitizedHtml));
    return this;
  }

  getSvgIcon(name: string): SVGSVGElement | null {
    const config = this.svgMap.get(name);
    if (!config) {
      return null;
    }
    const svg = this.getSvgElementFromConfig(config);
    return cloneSvg(svg);
  }

  private createSvgElementFromString(content: string): SVGSVGElement {
    const div = this.document.createElement('div');
    div.innerHTML = content;
    const svg = div.querySelector('svg');

    if (!svg) {
      throw Error('<svg> tag not found');
    }

    return svg;
  }

  private getSvgElementFromConfig(config: SvgIconConfig): SVGSVGElement {
    if (!config.svgElement) {
      const svg = this.createSvgElementFromString(config.content.toString());
      svg.setAttribute('fit', '');
      svg.setAttribute('fill', 'currentColor');
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');
      svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
      svg.setAttribute('focusable', 'false');
      config.svgElement = svg;
    }

    return config.svgElement;
  }
}

/** Clones an SVGElement while preserving type information. */
function cloneSvg(svg: SVGSVGElement): SVGSVGElement {
  return svg.cloneNode(true) as SVGSVGElement;
}
