import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';
import { TransitionDuration, TransitionEasing } from '../animation';

export const profileDropdownAnimations: {
  readonly showHideScaleAndOpacity: AnimationTriggerMetadata;
} = {
  showHideScaleAndOpacity: trigger('showHideScaleAndOpacity', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'scale(0.95)',
      }),
      animate(
        `${TransitionDuration.DURATION_100} ${TransitionEasing.EASE_OUT}`,
        style({ opacity: 1, transform: 'scale(1)' }),
      ),
    ]),
    transition(':leave', [
      style({
        opacity: 1,
        transform: 'scale(1)',
      }),
      animate(
        `${TransitionDuration.DURATION_100} ${TransitionEasing.EASE_IN}`,
        style({ opacity: 0, transform: 'scale(0.95)' }),
      ),
    ]),
  ]),
};
