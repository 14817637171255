import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type ContainerMaxWidth = '7xl' | 'container';

interface ContainerConfig {
  maxWidth: string;
}

const maxWidths: Record<ContainerMaxWidth, ContainerConfig['maxWidth']> = {
  '7xl': 'max-w-7xl',
  container: 'container',
};
const defaultMaxWidth: ContainerMaxWidth = '7xl';

@Component({
  standalone: true,
  selector: 'wma-container',
  templateUrl: 'container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'classes()',
  },
})
export class ContainerComponent {
  maxWidth = input<ContainerMaxWidth>();
  classes = computed(() => {
    return `block ${maxWidths[this.maxWidth() || defaultMaxWidth]} mx-auto px-4 sm:px-6 lg:px-8 py-4`;
  });
}
