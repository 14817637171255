import { Component, input, output } from '@angular/core';
import { profileDropdownAnimations } from './profile-dropdown.animations';

import { OverlayModule } from '@angular/cdk/overlay';
import { CdkMenuModule } from '@angular/cdk/menu';
import { AvatarComponent, AvatarSize } from '../avatar';

@Component({
  standalone: true,
  selector: 'wma-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  animations: [profileDropdownAnimations.showHideScaleAndOpacity],
  imports: [OverlayModule, CdkMenuModule, AvatarComponent],
})
export class ProfileDropdownComponent {
  user = input.required<{ picture?: string; name?: string }>();
  size = input<AvatarSize>('sm');
  logout = output<void>();

  emitLogout(): void {
    this.logout.emit();
  }
}
