import { NgModule } from '@angular/core';
import { FontSizeDirective } from './font-size.directive';
import { FontWeightDirective } from './font-weight.directive';
import { TypeScaleDirective } from './type-scale.directive';

@NgModule({
  declarations: [FontSizeDirective, FontWeightDirective, TypeScaleDirective],
  exports: [FontSizeDirective, FontWeightDirective, TypeScaleDirective],
})
export class TypographyModule {}
