import { computed, Directive, input } from '@angular/core';

export type FontSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';

const sizes: Record<FontSize, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg sm:text-xl',
  xl: 'text-xl sm:text-2xl',
  '2xl': 'text-2xl sm:text-3xl',
  '3xl': 'text-3xl sm:text-4xl',
  '4xl': 'text-4xl sm:text-5xl',
};

const defaultFontSize: FontSize = 'md';

@Directive({
  selector: '[wmaFontSize]',
  host: {
    '[class]': 'hostClasses()',
  },
})
export class FontSizeDirective {
  size = input<FontSize | undefined>(undefined, {
    alias: 'wmaFontSize',
  });

  /* The CSS classname applied to the host element. */
  hostClasses = computed(() => {
    return `${sizes[this.size() || defaultFontSize]}`;
  });
}
