export class TransitionEasing {
  static EASE_LINEAR = 'linear';
  static EASE_IN_OUT = 'cubic-bezier(0.4, 0, 0.2, 1)';
  static EASE_OUT = 'cubic-bezier(0, 0, 0.2, 1)';
  static EASE_IN = 'cubic-bezier(0.4, 0, 1, 1)';
}

export class TransitionDuration {
  static DURATION_100 = '100ms';
  static DURATION_300 = '300ms';
}
