<nav
  class="space-y-1 overflow-hidden rounded-lg border border-gray-300 bg-white"
  aria-label="Navigation"
>
  @for (link of links(); track link.to) {
    <a
      [routerLink]="link.to"
      [queryParams]="link.queryParams"
      [queryParamsHandling]="link.queryParamsHandling"
      routerLinkActive
      [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
      #rla="routerLinkActive"
      class="group flex items-center border-l-4 px-3 py-2 text-sm font-medium"
      [class]="rla.isActive ? klass().active : klass().inactive"
      [attr.aria-current]="rla.isActive"
    >
      {{ link.label }}
    </a>
  }
</nav>
