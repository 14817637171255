import { Component, computed, contentChild, Directive, input } from '@angular/core';

export type AlertColor = 'info' | 'warning' | 'success' | 'danger';
const defaultColor: AlertColor = 'info';
type AlertConfig = Record<'background' | 'icon' | 'title' | 'content', string[]> & {
  svgPathD: string;
};

const info: AlertConfig = {
  background: ['bg-primary-100', 'border-primary-200'],
  icon: ['text-primary-500'],
  title: ['text-primary-800'],
  content: ['text-primary-700'],
  svgPathD:
    'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z',
};
const warning: AlertConfig = {
  background: ['bg-warning-100', 'border-warning-200'],
  icon: ['text-warning-500'],
  title: ['text-warning-800'],
  content: ['text-warning-700'],
  svgPathD:
    'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z',
};
const success: AlertConfig = {
  background: ['bg-success-100', 'border-success-200'],
  icon: ['text-success-500'],
  title: ['text-success-800'],
  content: ['text-success-700'],
  svgPathD:
    'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z',
};
const danger: AlertConfig = {
  background: ['bg-danger-100', 'border-danger-200'],
  icon: ['text-danger-500'],
  title: ['text-danger-800'],
  content: ['text-danger-700'],
  svgPathD:
    'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z',
};

const colors: Record<AlertColor, AlertConfig> = {
  info,
  warning,
  success,
  danger,
};

@Directive({
  selector: `wma-alert-title, [wma-alert-title], [wmaAlertTitle]`,
})
export class AlertTitleDirective {}

@Directive({
  selector: `wma-alert-content, [wma-alert-content], [wmaAlertContent]`,
})
export class AlertContentDirective {}

@Component({
  selector: 'wma-alert',
  templateUrl: './alert.html',
  exportAs: 'wmaAlert',
  host: {
    '[attr.role]': '"alert"',
    '[class]': 'classes()',
  },
})
export class AlertComponent {
  _titleChild = contentChild(AlertTitleDirective);
  _contentChild = contentChild(AlertContentDirective);

  color = input<AlertColor>();
  config = computed(() => {
    return colors[this.color() || defaultColor];
  });
  classes = computed(() => {
    return ['block', 'rounded-lg', 'p-4', 'border', ...this.config().background];
  });
}
