<nav
  class="mx-auto -mb-px flex max-w-7xl space-x-8 overflow-x-auto px-4 sm:px-6 lg:px-8"
  aria-label="Navigation"
>
  @for (link of links(); track link.to) {
    <a
      [routerLink]="link.to"
      [queryParams]="link.queryParams"
      [queryParamsHandling]="link.queryParamsHandling"
      routerLinkActive
      [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
      #rla="routerLinkActive"
      class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
      [class]="
        rla.isActive
          ? 'border-primary-500 text-primary-600'
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
      "
      [attr.aria-current]="rla.isActive"
    >
      {{ link.label }}
    </a>
  }
</nav>
