import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavLink } from '@welma-education/common';

@Component({
  standalone: true,
  selector: 'wma-tabs-with-underline',
  templateUrl: 'tabs-with-underline.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'block',
  },
  imports: [RouterLinkActive, RouterLink],
})
export class TabsWithUnderlineComponent {
  links = input<NavLink[]>([]);
}
