<div class="flex space-x-3">
  <div class="shrink-0">
    <svg
      class="size-5"
      [class]="config().icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path fill-rule="evenodd" [attr.d]="config().svgPathD" clip-rule="evenodd" />
    </svg>
  </div>
  <div class="flex-1 space-y-2">
    @if (_titleChild()) {
      <div class="text-sm font-medium" [class]="config().title">
        <ng-content select="wma-alert-title, [wma-alert-title], [wmaAlertTitle]"></ng-content>
      </div>
    }
    @if (_contentChild()) {
      <div class="text-sm" [class]="config().content">
        <ng-content select="wma-alert-content, [wma-alert-content], [wmaAlertContent]"></ng-content>
      </div>
    }
  </div>
</div>
