import { NgModule } from '@angular/core';
import { DrawerContainerComponent, DrawerContentComponent, DrawerSidenavComponent } from './drawer';

export const DRAWER_DIRECTIVES = [
  DrawerContainerComponent,
  DrawerContentComponent,
  DrawerSidenavComponent,
];

@NgModule({
  imports: [DRAWER_DIRECTIVES],
  exports: [DRAWER_DIRECTIVES],
})
export class DrawerModule {}
