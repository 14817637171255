import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type BadgeColor = 'primary' | 'success' | 'info' | 'warning' | 'danger';
const defaultColor: BadgeColor = 'primary';

export type BadgeSize = 'md' | 'lg';
const defaultSize: BadgeSize = 'md';

export type BadgeVariant = 'rounded' | 'pill';
const defaultVariant: BadgeVariant = 'pill';

const colors: Record<BadgeColor, string[]> = {
  primary: ['bg-primary-100', 'text-primary-800'],
  info: ['bg-gray-50', 'text-gray-800'],
  warning: ['bg-warning-100', 'text-warning-800'],
  success: ['bg-success-100', 'text-success-800'],
  danger: ['bg-danger-100', 'text-danger-800'],
};

const sizes: Record<BadgeSize, string[]> = {
  md: ['px-2.5', 'py-0.5', 'text-xs', 'font-medium'],
  lg: ['px-3', 'py-0.5', 'text-sm', 'font-medium'],
};

const variants: Record<BadgeVariant, string[]> = {
  pill: ['rounded-full'],
  rounded: ['rounded'],
};

@Component({
  standalone: true,
  selector: 'wma-badge',
  templateUrl: './badge.html',
  exportAs: 'wmaBadge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'classes()',
  },
})
export class BadgeComponent {
  variant = input<BadgeVariant>();
  color = input<BadgeColor>();
  size = input<BadgeSize>();

  classes = computed(() => {
    return [
      'inline-flex',
      'items-center',
      ...sizes[this.size() || defaultSize],
      ...colors[this.color() || defaultColor],
      ...variants[this.variant() || defaultVariant],
    ];
  });
}
